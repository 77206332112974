import * as React from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import { Button, Container, Paper } from "@mui/material";
import { PartData } from "components";

export default function Hero(props) {
  function showMostPicked() {
    window.scrollTo({
      top: props?.refMostPicked?.current.offsetTop - 30,
      behavior: "smooth",
    });
  }

  return (
    <Container elevation={6} sx={{ display: "flex", mb: 2, paddingTop: 4 }}>
      <Grid container >
        <Grid item xs={12}>
          <CardContent sx={{ flex: 1, paddingLeft: 4 }}>
            <Typography component="h2" variant="h5" >
              Begin your excursion journey with us
            </Typography>
            <Typography variant="subtitle1" paragraph>
              Enjoy your holiday with free entry, <br />
              Time for more unforgettable moments.
            </Typography>
            <Button
              variant="contained"
              onClick={showMostPicked}
            >
              Let's start
            </Button>
          </CardContent>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" color="primary" sx={{ mt: 5, }}>
            <PartData
              travelers={props?.data?.travelers}
              treasures={props?.data?.treasures}
              cities={props?.data?.cities}
            />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
