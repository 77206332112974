import React from "react";
import Fade from "react-reveal/Fade";
import decode from "jwt-decode";

import { InputText } from "components/atoms/Form";
import { Box, Card, CardMedia, Grid, TextField, Typography } from "@mui/material";
import env from "configs/vars";

export default function BookingInformation(props) {
  const { data, ItemDetails, checkout } = props;
  const localStorageRes = JSON.parse(localStorage.getItem("profile"));
  const token = localStorageRes?.token;
  const decodedToken = decode(token);
  return (
    <Grid container>
      <Grid xs={6}>
        <CardMedia
          component="img"
          image={`${env.urlBucket}/item/${ItemDetails.imageId[0].imageUrl}`}
          style={{ maxHeight: "50vh" }}
        />
        <Typography variant="h5" xs={{ mt: 2 }}>
          {ItemDetails.title}
        </Typography>
        <Typography variant="subtitle1">
          {" "}
          {ItemDetails.city}, {ItemDetails.country}
        </Typography>
        <Typography variant="subtitle1">
          {" "}
          {+checkout.duration * ItemDetails.price}K / {checkout.duration}{" "}
          {ItemDetails.unit}
          {+checkout.duration > 1 ? "s" : ""}
        </Typography>
      </Grid>
      <Grid xs={6}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Card sx={{ padding: 2, boxShadow: 2, ml: 2 }}>
            <TextField
              id="firstName"
              name="firstName"
              label="firstName"
              value={decodedToken?.name?.split(" ")[0]}
              onChange={props.onChange}
            />

            <TextField
              id="lastName"
              name="lastName"
              label="lastName"
              value={decodedToken?.name?.split(" ")[1]}
              onChange={props.onChange}
            />

            <TextField
              id="email"
              name="email"
              type="email"
              label="email"
              value={decodedToken?.email}
              onChange={props.onChange}
            />
            <TextField
              id="phone"
              name="phone"
              label="phone"
              type="tel"
              value={data.phone}
              onChange={props.onChange}
            />
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
