import React from 'react'

import formatNumber from "utils/formatNumber";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PinDropIcon from "@mui/icons-material/PinDrop";


const PartData = (props) => {
    const { travelers, treasures, cities, } = props;
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <LoyaltyIcon sx={{ mr: -2 }} />{formatNumber(travelers)} members
            <PhotoCameraIcon sx={{ mr: -2 }} />{formatNumber(treasures)} activities
            <PinDropIcon sx={{ mr: -2 }} />{formatNumber(cities)} cities
        </div>
    )
}


export default PartData
