import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { InputNumber, InputDate } from "components/atoms/Form";
import { Badge, Button, Card, Typography } from "@mui/material";

const BookingForm = (props) => {
  const [state, setState] = useState({
    data: {
      duration: 1,
      date: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    },
  });

  const updateData = (e) => {
    console.log("updateData", e.target.value);
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  useEffect(() => {
    const { data } = state;
    if (data.date !== data.date) {
      const startDate = new Date(date.startDate);
      const endDate = new Date(date.endDate);
      const countDuration = new Date(endDate - startDate).getDate();
      setState({
        data: {
          ...state.data,
          duration: countDuration,
        },
      });
    }

    if (data.duration !== data.duration) {
      const startDate = new Date(date.startDate);
      const endDate = new Date(
        startDate.setDate(startDate.getDate() + +duration - 1)
      );
      setState({
        ...state,
        data: {
          ...state.data,
          date: {
            ...state.data.date,
            endDate: endDate,
          },
        },
      });
    }
  });

  const startBooking = () => {
    const { data } = state;
    console.log("startBooking", data);
    props.startBooking({
      _id: props.itemDetails?._id,
      duration: data.duration,
      date: {
        startDate: data.date.startDate,
        endDate: data.date.endDate,
      },
    });
    props.history.push("/checkout");
  };
  const { date, duration } = state.data;

  return (
    <Card
      sx={{
        mr: 2,
        textAlign: "center",
        padding: 2,
        ml: 2,
        boxShadow: 2,
      }}
    >
      <Typography variant="h6" color="text.secondary">
        Book now
      </Typography>
      <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2, mb: 2 }}>
        IDR {props.itemDetails?.price * duration}K / {duration}{" "}
        {props.itemDetails?.unit}
      </Typography>

      <InputNumber
        max={30}
        suffix={" night"}
        isSuffixPlural
        onChange={updateData}
        name="duration"
        value={duration}
      />

      <Badge badgeContent="!!" color="warning" sx={{ mt: 2, }}>
        {" "}
        <Typography variant="subtitle1" sx={{ mr: 2, mb: 2, }}>
          Upcoming features
        </Typography>
      </Badge>

      <Typography variant="subtitle1" sx={{ mb: 2 }}>Or pick range date here</Typography>

      <InputDate onChange={updateData} name="date" value={date} />

      <Button
        variant="contained"
        onClick={startBooking}
      >
        Continue to Book
      </Button>
    </Card >
  );
};

export default withRouter(BookingForm);
