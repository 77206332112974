import React, { useState } from "react";

import { InputText, InputFile } from "components/atoms/Form";
import { Box, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import env from "configs/vars";

export default function Payment(props) {
  const { data, ItemDetails, checkout, page } = props;
  const [checkedBank, setCheckedBank] = useState();
  let element = {};
  for (const key in page) {
    if (Object.hasOwnProperty.call(page, key)) {
      let e = page[key];
      element = { data: e };
    }
  }
  element = { ...element.data };
  const tax = 11;
  const subTotal = ItemDetails.price * checkout.duration;
  const grandTotal = (subTotal * tax) / 110 + subTotal;

  return (
    <Grid container>
      <Grid xs={6}>
        <Typography>
          Items {checkout.duration} {ItemDetails.unit}
          {+checkout.duration > 1 ? "s" : ""} / {subTotal}K + {tax}% (Tax)
        </Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Total payment : {grandTotal}K
        </Typography>
      </Grid>

      <Grid xs={6}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="on"
        >
          <Card sx={{ padding: 2, boxShadow: 2, ml: 2 }}>
            <Typography variant="subtitle1">
              Upload
            </Typography>
            <InputFile
              accept="image/*"
              id="proofPayment"
              name="proofPayment"
              value={data.proofPayment}
              onChange={props.onChange}
            />
            <FormControl component="fieldset" style={{ paddingTop: 20 }}>
              <RadioGroup >
                {[
                  {
                    id: 1,
                    name: "BRI",
                    logo: "logo-bri.png",
                    rek: "1111xxxxx"

                  },
                  {
                    id: 2,
                    name: "MANDIRI",
                    logo: "logo-mandiri.jpg",
                    rek: "2222xxxxx"

                  },
                  {
                    id: 3,
                    name: "BCA",
                    logo: "logo-bca.jpg",
                    rek: "3333xxxxx"

                  }
                ].map(bank => (
                  <Grid container key={bank.id} style={{ paddingTop: 20 }}>
                    <Grid item xs={3} >
                      <FormControlLabel
                        name="bankName"
                        value={bank.name}
                        control={<Radio />}
                        onChange={props.onChange}
                      />
                    </Grid>
                    <Grid item>
                      <img src={`${env.urlBucket}/bank/${bank.logo}`} width="60" />
                      <Typography>{bank.rek}</Typography>
                    </Grid>

                  </Grid>
                ))}
              </RadioGroup>
            </FormControl>

            <TextField
              id="bankHolder"
              name="bankHolder"
              label="Holder"
              type="text"
              value={data.bankHolder}
              // value={decodedToken?.name}
              onChange={props.onChange}
              style={{ display: "none" }}
            />
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
