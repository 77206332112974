import React from 'react'

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { CardActionArea } from "@mui/material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const CardItem = (props) => {
    const { key, href, image, title, city, country, price, unit, isPopular } = props;
    return (
        <Card
            sx={{ minWidth: 250, maxWidth: 300, maxHeight: 300, mr: 2 }}
            key={key}
        >
            <CardActionArea
                href={href}
                style={{
                    textDecoration: "none"
                }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={image}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {city}, {country}{" "}
                    </Typography>
                    <Typography
                        variant="body2"
                        component="text.secondary"
                        alignLeft
                    >
                        IDR{price}K / {unit}
                    </Typography>
                    {isPopular && (
                        <Typography variant="subtitle2" >
                            <ThumbUpIcon color="primary" sx={{ mr: 1 }} />
                            Popular
                        </Typography>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default CardItem
