

export const CHECKOUT_BOOKING = "CHECKOUT_BOOKING";
export const FETCH_BY = "FETCH_BY";
export const FETCH_PAGE = "FETCH_PAGE";
export const FETCH_MENU = "FETCH_MENU";
export const FETCH_ALL_POST = 'FETCH_ALL_POST';

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const END_LOADING = "END_LOADING";
export const START_LOADING = "START_LOADING";
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const FETCH_MESSAGE_BY = 'FETCH_MESSAGE_BY';

export const FETCH_USERS_GIT = 'FETCH_USERS_GIT';
export const FETCH_POSTS_GIT = 'FETCH_POSTS_GIT';
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";