import { CHECKOUT_BOOKING } from "../../constants/actionTypes";
import axios from "configs/axios";

export const checkoutBooking = (payload) => (dispatch) => {
  dispatch({
    type: CHECKOUT_BOOKING,
    payload: payload,
  });
};

// export const checkoutBooking =
//   (payload, successCB, failedCB) => async (dispatch) => {
//     console.log("payload  actions checkoutBooking", payload);
//     dispatch({ type: CHECKOUT_BOOKING, payload: payload });
//     return successCB && successCB(payload);
//     // try {
//     //   return successCB && successCB(payload);
//     // } catch (error) {
//     //   return dispatch(failedCB && failedCB(error));
//     // }
//   };

export const submitBooking = (payload) => () => {
  return axios.post(`/customer/booking-page`, payload, {
    headers: { contentType: "multipart/form-data" },
  });
};
