import React from 'react'

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LandingPage from "pages/LandingPage/index";
import DetailsPage from "pages/DetailsPage/index";
import Checkout from "pages/Checkout/index";

import Auth from "pages/Auth/Auth";
import AuthVerify from "pages/Auth/AuthVerify";
import ResetPassword from "pages/Auth/ResetPassword";
import RecoverAccount from "pages/Auth/RecoverAccount";
import NewPassword from "pages/Auth/NewPassword";

import viewCategory from "pages/Admin/Category/viewCategory";
import viewItem from "pages/Admin/Item/viewItem";
import createCategory from "pages/Admin/Category/createCategory";
import createItem from "pages/Admin/Item/createItem";
import imageItem from "pages/Admin/Item/imageItem";
import detailItem from "pages/Admin/Item/detailItem";
import createFeature from "pages/Admin/Item/createFeature";
import createActivity from "pages/Admin/Item/createActivity";
import viewBooking from "pages/Admin/Booking/viewBooking";
import bookingDetail from "pages/Admin/Booking/bookingDetail";

import NotFound from "pages/404";

const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL,
});

const Routes = () => {
    const user = JSON.parse(localStorage.getItem("profile"));
    return (
        <>
            <Router history={history} basename={process.env.PUBLIC_URL}>
                <Switch>
                    <Route exact path="/" component={LandingPage} />
                    <Route exact path="/properties/:id" component={DetailsPage} />
                    <Route exact path="/checkout" component={Checkout} />

                    <Route path="/auth" exact component={() => (!user?.result ? <Auth /> : <Redirect to="/" />)} />
                    <Route path="/authVerify/:token" component={AuthVerify} />
                    <Route path="/resetPassword" exact component={ResetPassword} />
                    <Route path="/recoverAccount" exact component={RecoverAccount} />
                    <Route path="/newPassword/:token" exact component={NewPassword} />
                    {/* admin */}
                    <Route path="/admin" exact component={() => <Redirect to="/admin/viewBooking" />} />
                    <Route path="/admin/viewCategory" component={viewCategory} />
                    <Route exact path="/admin/createCategory/:currentId?" component={createCategory} />
                    <Route exact path="/admin/viewItem" component={viewItem} />
                    <Route exact path="/admin/createItem/:currentId?" component={createItem} />
                    <Route exact path="/admin/imageItem/:currentId?" component={imageItem} />
                    <Route exact path="/admin/detailItem/:currentId?" component={detailItem} />
                    <Route exact path="/admin/createFeature/:currentId?" component={createFeature} />
                    <Route exact path="/admin/createActivity/:currentId?" component={createActivity} />
                    <Route exact path="/admin/viewBooking" component={viewBooking} />
                    <Route exact path="/admin/bookingDetail/:currentId?" component={bookingDetail} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Router>

            <ToastContainer></ToastContainer>
        </>
    )
}

export default Routes;
