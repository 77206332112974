// import React, { Component } from "react";
import React, { useState, useEffect } from "react";
import { useDispatch, } from "react-redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import Header from "components/molecules/Header";
import PageDetailTitle from "components/molecules/PageDetailTitle";
import FeaturedImage from "components/molecules/FeaturedImage";
import PageDetailDescription from "components/molecules/PageDetailDescription";
import BookingForm from "components/molecules/BookingForm";
import Activities from "components/molecules/Activities";
import Footer from "components/molecules/Footer";

import { checkoutBooking } from "store/actions/checkout";
import { fetchPage } from "store/actions/page";
import { getAllDetailsPage } from "store/actions/detailsPage";

function DetailsPage(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    dispatch(getAllDetailsPage(id, `${id}`));
    // setIsLoading(false);
  }, [dispatch]);

  // console.log("dataDetailsPage", dataDetailsPage);

  const breadcrumb = [
    { pageTitle: "Home", pageHref: "" },
    { pageTitle: "House Details", pageHref: "" },
  ];

  const { page, match } = props;
  return (
    <Container spacing={2}>
      <Header {...props} />
      <PageDetailTitle breadcrumb={breadcrumb} data={page[match.params.id]} />
      <Grid container>
        <Grid xs={8}>
          <FeaturedImage data={page[match.params.id]?.imageId} />
        </Grid>
        <Grid xs={4}>
          <BookingForm
            itemDetails={page[match.params.id]}
            startBooking={props.checkoutBooking}
          />
        </Grid>
      </Grid>
      <PageDetailDescription data={page[match.params.id]} />
      <Activities data={page[match.params.id]?.activityId} />

      <Footer />
    </Container>
  );
}

// export default connect(null, { checkoutBooking })(DetailsPage);
const mapStateToProps = (state) => ({
  page: state.page,
});

export default connect(mapStateToProps, { checkoutBooking, fetchPage })(
  DetailsPage
);
