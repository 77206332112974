import {
  FETCH_ALL,
  FETCH_PAGE,
  UPDATE,
  DELETE,
} from "../../constants/actionTypes";

import axios from "axios";
const API = axios.create({ baseURL: process.env.REACT_APP_HOST });

export const getAll = (successCB, failedCB) => async (dispatch) => {
  API.get(`/v1/customer/landing-page`)
    .then((response) => {
      const resAPI = response.data;

      dispatch({ type: FETCH_ALL, payload: resAPI });
      return successCB && successCB(resAPI);
    })
    .catch((err) => {
      console.log("err reducer==", err);

      return failedCB && failedCB(err);
    });
};

export const getAllDetailsPage =
  (id, page, successCB, failedCB) => async (dispatch) => {
    API.get(`/v1/customer/detail-page/${id}`)
      .then((response) => {
        const resAPI = response.data;
        dispatch({
          type: FETCH_PAGE,
          payload: {
            [page]: resAPI,
          },
        });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {
        console.log("err reducer==", err);

        return failedCB && failedCB(err);
      });
  };

export const confirmBooking =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("id", formData._id);
    API.put(`/admin/booking/${currentId}/confirmation`, data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI", resAPI);
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const rejectBooking =
  (currentId, formData, successCB, failedCB) => async (dispatch) => {
    const data = new FormData();
    data.append("id", formData._id);
    API.put(`/admin/booking/${currentId}/reject`, data)
      .then((response) => {
        const resAPI = response.data;
        console.log("resAPI", resAPI);
        dispatch({ type: UPDATE, payload: resAPI });
        return successCB && successCB(resAPI);
      })
      .catch((err) => {

        return failedCB && failedCB(err);
      });
  };

export const delBooking = (id, successCB, failedCB) => async (dispatch) => {
  API.delete(`/admin/item/${id}/delete`)
    .then((response) => {
      const resAPI = response.data;
      dispatch({ type: DELETE, payload: resAPI });
      return successCB && successCB(resAPI);
    })
    .catch((err) => {

      return failedCB && failedCB(err);
    });
};
