import { Button, Container, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
function NotFound() {
  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 8 }}>
      <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 4 }}>
        404
      </Typography>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Halaman yang Anda cari tidak ditemukan
      </Typography>
      <Button variant="contained" component={RouterLink} to="/" size="large">
        Kembali ke Beranda
      </Button>
    </Container>
  );
}

export default NotFound;
