import React from "react";
import Typography from '@mui/material/Typography';
import Link from "@mui/material/Link";
import Grid from '@mui/material/Grid';


const Footer = () => {
  return (
    <footer style={{
      padding: 50,
      marginTop: 'auto',
    }}>
      <Grid container spacing={3} justify="space-between">
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Copyright
            {new Date().getFullYear()}
          </Typography>
        </Grid>
        <Grid item>
          <Link color="textSecondary" href="#">
            Terms of use
          </Link>
          {' | '}
          <Link color="textSecondary" href="#">
            Privacy policy
          </Link>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;