import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
} from "@mui/material";

import { toastProperties } from "utils/toastProperties";

import {
  confirmBooking,
  rejectBooking,
  showDetailBooking,
} from "store/actions/booking";
import env from "configs/vars";

const theme = createTheme();

export default function BookingDetail() {
  const initialState = {
    _id: "",
    name: "",
    qty: "",
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { currentId } = params;
  const { dataBookingReducer } = useSelector((state) => state.bookingReducer);
  const [form, setForm] = useState(initialState);

  useEffect(() => {
    if (currentId) {
      dispatch(showDetailBooking(currentId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentId) {
      if (dataBookingReducer) {
        setForm(dataBookingReducer.booking);
      }
    }
  }, [dataBookingReducer]);


  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const type = e.target.value;

    if (type === "Confirm") {
      dispatch(
        confirmBooking(
          currentId,
          form,
          (res) => {
            toast.success(res?.message, toastProperties);
            history.push(`/admin/viewBooking`);
          },
          (error) => {
            toast.error(
              error?.response?.message || "Something went wrong",
              toastProperties
            );
          }
        )
      );
    } else {
      dispatch(
        rejectBooking(
          currentId,
          form,
          (res) => {
            toast.success(res?.message, toastProperties);
            history.push(`/admin/viewBooking`);
          },
          (error) => {
            toast.error(
              error?.message || "Something went wrong",
              toastProperties
            );
          }
        )
      );
    }
  }; // end handleSubmit

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <ReceiptIcon />
            </Avatar>
            <Typography variant="title">
              Booking Information
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
              autoComplete="on"
            >

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice</TableCell>
                      <TableCell>  <Typography>{form?.invoice}</Typography></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell><Typography>
                        {form?.itemId?.title} / {form?.itemId?.duration} night
                      </Typography></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Price</TableCell>
                      <TableCell> <Typography>{form?.itemId?.price}K</Typography></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Bank</TableCell>
                      <TableCell> <Typography>{form?.payments?.bankFrom}</Typography></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Sender</TableCell>
                      <TableCell>
                        <Typography>{form?.payments?.accountHolder}</Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={2}>
                        <img
                          className="img rounded mx-auto d-block"
                          style={{ height: 300 }}
                          src={`${env.urlBucket}/booking/${form?.payments?.proofPayment}`}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Typography>
                  {form?.bankId?.name} {form?.bankId?.nameBank}
                </Typography>
                <TextField
                  onChange={onChange}
                  value={form?._id}
                  name="_id"
                  hidden
                  required
                  fullWidth
                  style={{ display: 'none' }}
                />
                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={handleSubmit}
                    value="Confirm"
                    type="submit"
                    fullWidth
                    variant="contained"
                  >
                    Confirmation
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    onClick={handleSubmit}
                    value="Reject"
                    type="submit"
                    fullWidth
                    variant="outlined"
                  >
                    Reject
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      </Container >
    </ThemeProvider >
  );
}
