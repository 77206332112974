import React from "react";
import Fade from "react-reveal/Fade";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Typography } from "@mui/material";

export default function Completed() {
  return (
    <Fade>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TaskAltIcon color="primary" sx={{ fontSize: 33 }}></TaskAltIcon>
      </div>
      <Typography align="center">
        {" "}
        We will inform you via email later once the transaction has been
        accepted
      </Typography>
    </Fade>
  );
}
