import {
  CREATE,
  FETCH_ALL,
  FETCH_BY,
  UPDATE,
  DELETE,
} from "../../constants/actionTypes";

const initialState = {
  dataDetailsPageReducerAll: [],
  dataDetailsPageReducer: [],
};

const detailsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE:
      return {
        ...state,
      };
    case FETCH_ALL:
      return {
        ...state,
        dataDetailsPageReducerAll: action.payload,
      };
    case FETCH_BY:
      return {
        ...state,
        dataDetailsPageReducer: action.payload,
      };
    case UPDATE:
      return {
        ...state,
      };
    case DELETE:
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default detailsPageReducer;
