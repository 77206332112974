import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { Container, Paper, } from "@mui/material";

import Header from "components/molecules/Header";
import Hero from "pages/Hero";
import MostPicked from "pages/MostPicked";
import Categories from "pages/Categories";

import { getAllLandingPage, getAllDashboard, } from "store/actions/landingPage";
import { Loading } from "components";

function LandingPage(props) {
  const user = useState(JSON.parse(localStorage.getItem('profile')));
  const refMostPicked = useRef();
  const dispatch = useDispatch();
  const { page } = props;

  const { isLoading } = useSelector((state) => state.landingPageReducer);

  const welcome = () =>
    toast.info(`${user?.result?.name ? `Hi, ` + user?.result?.name : ""}
    Welcome to my practice web`, {
      autoClose: 4000,
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });


  useEffect(() => {
    document.title = "Home";
    dispatch(getAllLandingPage("landingPage"));
    welcome();
  }, [dispatch]);


  useEffect(() => {
    dispatch(getAllDashboard("dashboard"));
  }, [dispatch]);

  if (!page.hasOwnProperty("landingPage")) return <Loading isLoading />;

  return (
    <Container spacing={2}>
      <Header></Header>
      <ToastContainer autoClose={8000} />
      <Hero refMostPicked={refMostPicked} data={page?.landingPage?.hero} dashboard={page?.dashboard} />
      <MostPicked refMostPicked={refMostPicked} data={page?.landingPage?.mostPicked} />
      <Categories data={page?.landingPage?.category} />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  page: state.page,
});

export default connect(mapStateToProps, { getAllLandingPage, getAllDashboard, })(LandingPage);