
import Box from "@mui/material/Box";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  ImageList,
  ImageListItem,
} from "@mui/material";

import { getItemImage } from "store/actions/item";
import env from "configs/vars";


export default function Detail() {
  const initialState = {
    _id: "",
    imageUrl: "",
  };
  const dispatch = useDispatch();
  const params = useParams();
  const { currentId } = params;

  const [image, setImage] = useState(initialState);
  const { dataItemReducer } = useSelector((state) => state.itemReducer);

  useEffect(() => {
    dispatch(getItemImage(currentId));
  }, [dispatch]);

  useEffect(() => {
    setImage(dataItemReducer);
    // const imageUrl = dataItemReducer.map((el) => el.imageUrl);
    // setForm((form) => ({ ...form }));
  }, [dataItemReducer]);

  console.log("image", image);
  return image.length > 0 ? (
    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
      {image.map((item) => (
        <ImageListItem key={item._id}>
          <img
            src={`${env.urlBucket}/item/${item.imageUrl}`}
            alt={item.imageUrl}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  ) : (
    <Box>Data kosong</Box>
  );
}
