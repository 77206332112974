import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {
  viewDetailItem,
  delFeatureItem,
  delActivityItem,
} from "store/actions/item";
import { Button, CircularProgress, Link } from "@mui/material";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastProperties } from "utils/toastProperties";

export default function Item() {
  const history = useHistory();
  const params = useParams();
  const { currentId } = params;
  const dispatch = useDispatch();
  const { dataItemReducerAll } = useSelector((state) => state.itemReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [dataFeature, setDataFeature] = useState([]);
  const [dataActivity, setDataActivity] = useState([]);
  const [isFeature, setIsFeature] = useState(true);

  const switchMode = () => {
    setIsFeature((prevIsSignup) => !prevIsSignup);
  };

  useEffect(() => {
    if (currentId) {
      dispatch(viewDetailItem(currentId));
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (dataItemReducerAll) {
      setDataFeature(dataItemReducerAll.feature);
      setDataActivity(dataItemReducerAll.activity);
    }
  }, [dataItemReducerAll]);

  console.log("dataFeature", dataFeature);
  console.log("dataActivity", dataActivity);

  const onDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Apakah Anda yakin akan menghapus data ini?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            isFeature
              ? dispatch(
                delFeatureItem(
                  currentId,
                  id,
                  (res) => {
                    const newData = dataFeature.filter(
                      (data) => data._id !== id
                    );
                    setDataFeature(newData);
                    toast.success(res?.message, toastProperties);
                    setIsLoading(false);
                    history.push(`/admin/detailItem/${currentId}`);
                  },
                  (error) => {
                    toast.error(
                      error?.response?.message || "Something went wrong",
                      toastProperties
                    );
                    setIsLoading(false);
                    history.push(`/admin/detailItem/${currentId}`);
                  }
                )
              )
              : dispatch(
                delActivityItem(
                  currentId,
                  id,
                  (res) => {
                    const newData = dataFeature.filter(
                      (data) => data._id !== id
                    );
                    setDataActivity(newData);
                    isFeature(true);
                    toast.success(res?.message, toastProperties);
                    setIsLoading(false);
                    history.push(`/admin/detailItem/${currentId}`);
                  },
                  (error) => {
                    toast.error(
                      error?.response?.message || "Something went wrong",
                      toastProperties
                    );
                    setIsLoading(false);
                    history.push(`/admin/detailItem/${currentId}`);
                  }
                )
              );
          },
        },
        {
          label: "Tidak",
          onClick: () => console.log("user tidak setuju"),
        },
      ],
    });
  };

  const onUpdate = (id) => {
    isFeature
      ? history.push(`/admin/createFeature/${currentId}/?featureId=${id}`)
      : history.push(`/admin/createActivity/${currentId}/?activityId=${id}`);
  };
  const onCreate = () => {
    isFeature
      ? history.push(`/admin/createFeature/${currentId}`)
      : history.push(`/admin/createActivity/${currentId}`);
  };

  return (
    <>
      {isLoading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <Button variant="outlined" onClick={onCreate}>
            <AddCircleOutlineIcon></AddCircleOutlineIcon>Create
          </Button>
          <Link variant="body2" onClick={switchMode} sx={{ marginLeft: 1 }}>
            {isFeature ? (
              <Button variant="contained">
                <DoubleArrowIcon></DoubleArrowIcon>Activity
              </Button>
            ) : (
              <Button variant="contained">
                <DoubleArrowIcon></DoubleArrowIcon>Feature
              </Button>
            )}
          </Link>
          <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table sx={{ minWidth: 50 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    {isFeature ? (
                      <Typography variant="h6" component="h6">
                        Feature
                      </Typography>
                    ) : (
                      <Typography variant="h6" component="h6">
                        Activity
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Name</TableCell>

                  {isFeature ? (
                    <TableCell>QTY</TableCell>
                  ) : (
                    <TableCell>Type</TableCell>
                  )}

                  <TableCell>ImageURL</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {isFeature
                ? dataFeature &&
                dataFeature.map((row) => (
                  <TableBody>
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.qty}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.imageUrl}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          sx={{ m: 1 }}
                          variant="contained"
                          onClick={() => onUpdate(row._id)}
                        >
                          Update
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => onDelete(row._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
                : dataActivity &&
                dataActivity.map((row) => (
                  <TableBody>
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.type}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.imageUrl}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          sx={{ m: 1 }}
                          variant="contained"
                          onClick={() => onUpdate(row._id)}
                        >
                          Update
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => onDelete(row._id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
