import React from "react";
import { ImageList, ImageListItem } from "@mui/material";
import SvgIcon from '@mui/material/SvgIcon';
import env from "configs/vars";

export default function FeaturedImage({ data }) {
  return (
    <ImageList
      sx={{ maxWidth: 800, maxHeight: 500, }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {data?.map((item, index) => {
        return (
          <ImageListItem key={index} cols={2} rows={2}>
            <img
              src={`${env.urlBucket}/item/${item?.imageUrl}`}
              alt=""
              loading="lazy"
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}
