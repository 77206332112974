import {
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

import ReactHtmlParser from "react-html-parser";
export default function PageDetailDescription({ data }) {
  return (
    <Grid container sx={{ display: "flex", mb: 5 }} >
      <Typography variant="h6"> Desc</Typography>
      <Grid xs={12} sx={{ mt: 2 }}>
        <Typography> {ReactHtmlParser(data?.description)}</Typography>
      </Grid>

      {data?.featureId?.length === 0 ? (
        <Grid xs={12} sx={{ mt: 2, textAlign: "center" }}>
          <Typography>Tidak Ada Feature</Typography>
        </Grid>
      ) : (
        data?.featureId?.map((feature, index) => {
          return (
            <Grid xs={1} style={{
              marginTop: 5,
              textAlign: "center",
            }}>
              <CardMedia
                style={{
                  maxWidth: 30,
                  maxHeight: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  extAlign: "center"
                }}
                src={require(`../../../assets/images/${feature.imageUrl}`)}
                component="img"
              />
              <Typography>{feature?.qty}</Typography>
              <Typography> {feature?.name}</Typography>
            </Grid>
          );
        })
      )}
    </Grid>
  );
}
