import React from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { Grid, Typography } from "@mui/material";
import env from "configs/vars";

export default function Activities({ data }) {
  return (
    <ImageList sx={{ width: 500, height: 600 }}>
      <ImageListItem key="Subheader" cols={2}>
        <Typography variant="h6">Activities</Typography>
      </ImageListItem>
      {data?.length === 0 ? (
        <Grid xs={12} sx={{ textAlign: "center" }}>
          <Typography>Tidak Ada Activities</Typography>
        </Grid>
      ) : (
        data?.map((item, index2) => (
          <ImageListItem key={`activity-item-${index2}`}>
            <img
              src={`${item?.imageUrl
                ? `${env.urlBucket}/activity/${item?.imageUrl}`
                : ""
                }?w=248&fit=crop&auto=format`}
              srcSet={`${item?.imageUrl
                ? `${env.urlBucket}/activity/${item?.imageUrl}`
                : ""
                }?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.name}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.name}
              subtitle={item.type}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.name}`}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))
      )}
    </ImageList>
  );
}
