import React, { useState } from "react";
import { connect } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BookingInformation from "components/molecules/Checkout/BookingInformation";
import Payment from "components/molecules/Checkout/Payment";
import Completed from "components/molecules/Checkout/Completed";
import { submitBooking } from "store/actions/checkout";
import Header from "components/molecules/Header";
import decode from "jwt-decode";
import { Chip } from "@mui/material";
import MoodIcon from "@mui/icons-material/Mood";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Checkout = (props) => {
  const theme = createTheme();
  const steps = ["Booking Form", "Payment", "Complete"];
  const [activeStep, setActiveStep] = useState(0);
  const initialState = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      proofPayment: "",
      bankName: "",
      bankHolder: "",
    },
  };
  const [state, setState] = useState(initialState);
  const { data } = state;
  const localStorageRes = JSON.parse(localStorage.getItem("profile"));
  const { checkout, page } = props;
  const [responseSuccess, setResponseSuccess] = useState();

  const handleNext = (submit) => {
    if (submit === "submit") {
      const localStorageRes = JSON.parse(localStorage.getItem("profile"));
      const token = localStorageRes?.token;
      const decodedToken = decode(token);
      const { data } = state;
      // console.log("datanextStep===>", data);
      const { checkout } = props;

      const payload = new FormData();
      payload.append("firstName", decodedToken.name.split(" ")[0]);
      payload.append("lastName", decodedToken.name.split(" ")[1]);
      payload.append("email", decodedToken.email);
      payload.append("phoneNumber", data.phone);
      payload.append("idItem", checkout._id);
      payload.append("duration", checkout.duration);
      payload.append("bookingStartDate", checkout.date.startDate);
      payload.append("bookingEndDate", checkout.date.endDate);
      payload.append("accountHolder", decodedToken.name);
      payload.append("bankFrom", data.bankName);
      payload.append("image", data.proofPayment[0]);
      // payload.append("bankId", checkout.bankId);
      props
        .submitBooking(payload)
        .then((response) => setResponseSuccess(response?.data?.booking))
        .catch((error) => {
          console.log("errornya", error);
          toast.error(error?.originalError?.code || "Something went wrong", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  console.log("responseSuccess Checkout", responseSuccess);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onChange = (event) => {
    setState({
      data: {
        ...state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  if (!localStorageRes)
    return (
      <Container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          mt: 35,
        }}
      >
        <Chip
          component="a"
          href="/auth"
          size="medium"
          color="primary"
          icon={<MoodIcon />}
          variant="h5"
          label="Sign In first please"
          style={{
            textDecoration: "none", color: "white"
          }}
        />
      </Container>
    );

  if (!checkout)
    return (
      <Container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          mt: 35,
        }}
      >
        <Chip
          onClick={() => props.history.goBack()}
          size="medium"
          color="primary"
          icon={<MoodIcon />}
          variant="h5"
          label="Choose your room"
          style={{
            textDecoration: "none", color: "white"
          }}
        />
      </Container>
    );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BookingInformation
            data={data}
            checkout={checkout}
            ItemDetails={page[checkout._id]}
            onChange={onChange}
          />
        );
      case 1:
        return (
          <Payment
            data={data}
            ItemDetails={page[checkout._id]}
            checkout={checkout}
            page={page}
            onChange={onChange}
          />
        );
      case 2:
        return <Completed />;
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header isCentered />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography variant="h6" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps?.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps?.length ? (
            <>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #{responseSuccess?.invoice}. We have
                emailed your order confirmation, and will send you an update
                when your order has shipped.
              </Typography>{" "}
              <Button href="/" variant="contained" sx={{ mt: 3, ml: 1 }}>
                Back to Homepage
              </Button>
            </>
          ) : (
            <>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                {data.phone !== "" && activeStep === 0 && (
                  <Button
                    variant="contained"
                    onClick={() => handleNext("")}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? "Place order" : "Next"}
                  </Button>
                )}

                {data.proofPayment !== "" &&
                  data.bankName !== "" &&
                  activeStep === 1 && (
                    <Button
                      variant="contained"
                      onClick={() => handleNext("submit")}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1 ? "Place order" : "Next"}
                    </Button>
                  )}
                {activeStep !== 0 && activeStep !== 1 && responseSuccess && (
                  <Button
                    variant="contained"
                    onClick={() => handleNext("")}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? "Place order" : "Next"}
                  </Button>
                )}
              </Box>
            </>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  page: state.page,
});

export default connect(mapStateToProps, { submitBooking })(Checkout);
