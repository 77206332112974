import React from "react";// import komponen React yang diperlukan
import { Container, Grid, Typography } from "@mui/material";
import env from "configs/vars";
import { CardItem } from "components";

export default function Categories({ data }) {
  const filteredData = data?.filter((el) => el?.itemId.length > 0);

  return filteredData.map((el, index) => (
    <Container fixed sx={{ paddingBottom: 3, paddingTop: 3 }} key={`category-${index}`}>
      <Grid xs={12}>
        <Typography gutterBottom variant="h6" component="div">
          {el?.name}
        </Typography>
      </Grid>
      <Grid sx={{ display: "flex" }}>
        {el?.itemId?.map((item, index) => (
          <CardItem
            key={index}
            href={`/properties/${item?._id}`}
            image={
              item?.imageId[0]
                ? `${env.urlBucket}/item/${item?.imageId[0]?.imageUrl}`
                : ""
            }
            title={item?.title}
            city={item?.city}
            country={item?.country}
            price={item?.price}
            unit={item?.unit}
            isPopular={item?.isPopular}
          />
        ))}
      </Grid>
    </Container>
  ));
}
