import React from "react";
import Flip from 'react-reveal/Flip';

import Breadcrumb from "components/atoms/Breadcrumb";
import { Typography } from "@mui/material";


export default function PageDetailTitle({ data, breadcrumb }) {
  return (
    <Flip bottom>
      <div style={{ marginTop: 30 }} >
        <Breadcrumb data={breadcrumb} />
      </div>
      <Typography variant="h5" style={{ marginTop: 30 }} >
        {data?.title}
      </Typography>
      <Typography variant="subtitle1">
        {data?.city}, {data?.country}
      </Typography>
    </Flip>
  );
}

