import { combineReducers } from "redux";
import checkout from "./checkout";
import page from "./page";
import auth from "./auth";
import categoryReducer from "./category";
import itemReducer from "./item";
import bookingReducer from "./booking";
import landingPageReducer from "./landingPage";
import detailsPageReducer from "./detailsPage";

export default combineReducers({
  auth,
  checkout,
  page,
  categoryReducer,
  itemReducer,
  bookingReducer,
  landingPageReducer,
  detailsPageReducer,
});
