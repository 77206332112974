import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import decode from "jwt-decode";
import { LOGOUT } from "constants/actionTypes";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import LoginTwoToneIcon from '@mui/icons-material/LoginTwoTone';
import { toast } from "react-toastify";
import { getMenu, signOut } from "store/actions/auth";
import "react-toastify/dist/ReactToastify.css";
import { toastProperties } from "utils/toastProperties"
import logo from "assets/images/logo.png";
import env from "configs/vars";


const Header = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { userMenu, } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMenu());
  }, [])

  const logout = () => {
    dispatch({ type: LOGOUT });
    history.push("auth")
    dispatch(
      signOut(
        user?.result?.email,
        (res) => {
          toast.success(res?.data?.message || "Your sign out successfully", {
            toastProperties
          });
          setUser(null);
        },
        (error) => {
          toast.error(
            error?.response?.data?.message,
            {
              toastProperties
            }
          );
        }
      )
    );
  };

  useEffect(() => {
    const token = user?.token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
  }, [location]);
  console.log("userMenu", userMenu);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar src={logo} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            style={{
              textDecoration: "none",
              color: 'white',
            }}
            sx={{
              ml: 2,
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: "none"
            }}
          >
            Staycation
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {userMenu.map((page, index) => (
                <MenuItem key={index}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            KalbarVacation
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            {userMenu?.map((page, index) => (
              <Button
                key={index}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Link href={page?.url} variant="body2" style={{ textDecoration: "none", color: "white" }} >
                  {page?.name}
                </Link>
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {user?.result ? (
              <>
                <IconButton sx={{ p: 0, mr: 2 }}>
                  {user?.result?._id ? (
                    <Avatar alt={user?.result?.name} src={`${env.urlBucket}/user/${user?.result?.imageUrl}`} />
                  ) : (
                    <Avatar src={user?.result?.imageUrl} />
                  )}
                </IconButton>
                <LogoutTwoToneIcon onClick={logout}></LogoutTwoToneIcon>
              </>
            ) : (
              <Link href="/auth" variant="body2" style={{ textDecoration: "none", color: "white" }}>
                <LoginTwoToneIcon ></LoginTwoToneIcon>
              </Link>
            )}

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
