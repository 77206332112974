import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import env from "configs/vars";
import { CardItem, } from "components";

export default function MostPicked(props) {
  const settings = {
    arrows: false,
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
  };


  return (
    <Slider {...settings} style={{ padding: 20 }} ref={props?.refMostPicked}>
      {props?.data?.map((item, index) => {
        return (
          <CardItem
            key={index}
            href={`/properties/${item?._id}`}
            image={
              item?.imageId[0]
                ? `${env.urlBucket}/item/${item?.imageId[0]?.imageUrl}`
                : ""
            }
            title={item?.title}
            city={item?.city}
            country={item?.country}
            price={item?.price}
            unit={item?.unit}
            isPopular={item?.isPopular}
          />
        );
      })}
    </Slider>
  );
}
